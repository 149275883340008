<template>
  <div class="penPosture">
    <div class="fingerContent">
      <div class="finger">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/finger_notTouch_single.png" alt="">
        <p>拇指与食指不相碰</p>
      </div>
    </div>
    <div class="fingerContainer">
        <div class="finger">
          <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/finger_cross_single.png" alt="">
          <p>拇指与食指不相碰</p>
        </div>
        <div class="finger">
          <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/finger_ touch_single.png" alt="">
          <p>拇指与食指不相碰</p>
        </div>
      </div>
    <button @click="go">返回</button>
  </div>
</template>

<script>
//import ViewPlan from "../eyeTest/viewPlan";
export default {
  name: "penPosture",
  //components: {ViewPlan},
  data() {
    return {
    }
  },
  created() {},
  mounted() {},
  methods: {
    go() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.penPosture {
  width: 100%;
  height: 100%;
  position: relative;
  background: url("http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/pedia_background.jpg") no-repeat center center;
  background-size: cover;
}
.fingerContent {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%,-50%);
}
.fingerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.fingerContainer, .fingerContent{
  display: flex;
  justify-content: center;
  align-items: center;
}
.finger {
  width: 350px;
  height: 350px;
  position: relative;
}
.finger img {
  width: 350px;
  height: 350px;
}
.finger p {
  position: absolute;
  left: 50%;
  bottom: 25%;
  transform: translate(-50%,0);
  color: rgb(255,120,0);
}
button {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
  font-size: 18px;
}
</style>
